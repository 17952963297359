import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { passiveSupport } from 'passive-events-support/src/utils';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import FormCheck from 'react-bootstrap/FormCheck';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';

import { useAuth0 } from '../react-auth0-wrapper';
import { getSchoolIds } from './FetchData';

import { FlashMessageContext } from '../context/flash-message';

import '../styles/dashboard.css';
import { getRelativeTimeString, renderSubheader, renderInfoToolTip } from './common';

export const Dashboard = () => {
  // [Violation] Added non-passive event listener to a scroll-blocking 'wheel' event.
  // Consider marking event handler as 'passive' to make the page more responsive.
  passiveSupport({ events: ['touchstart', 'touchmove'] });

  const API_DEBUG = process.env.REACT_APP_API_DEBUG === 'true';

  /***********************
   * INITIALIZATION
   ***********************/

  const { getTokenSilently } = useAuth0();

  const flashMessageContext = useContext(FlashMessageContext);

  const DEFAULT_SORT_COLUMN = 'lastUpdated';
  const DEFAULT_SORT_DIRECTION = 'asc';

  const [isLoaded, setIsLoaded] = useState(false);
  const [reloadAssessments, setReloadAssessments] = useState(false);
  const [assessments, setAssessments] = useState([]);
  const [sortColumn, setSortColumn] = useState(DEFAULT_SORT_COLUMN);
  const [sortDirection, setSortDirection] = useState(DEFAULT_SORT_DIRECTION);
  const [selectedAssessments, setSelectedAssessments] = useState([]);
  const [allRowsSelected, setAllRowsSelected] = useState(false);
  const [assessmentStatusCounts, setAssessmentStatusCounts] = useState({ notStarted: 0, running: 0, done: 0 });

  // Reducer
  function filterReducer(state, action) {
    switch (action.type) {
      case 'SET_TESTING_GROUP_FILTER':
        setAllRowsSelected(false);
        setSelectedAssessments([]);
        return { ...state, testingGroup: action.value === 'All' ? null : action.value };
      case 'SET_TEACHER_FILTER':
        setAllRowsSelected(false);
        setSelectedAssessments([]);
        return { ...state, teacher: action.value === 'All' ? null : action.value };
      case 'SET_STATUS_FILTER':
        setAllRowsSelected(false);
        setSelectedAssessments([]);
        return { ...state, status: action.value === 'All' ? null : action.value };
      case 'SET_IS_LOCKED_FILTER':
        setAllRowsSelected(false);
        setSelectedAssessments([]);
        return { ...state, isLocked: action.value === 'All' ? null : action.value };
      case 'RESET_FILTER':
        setAllRowsSelected(false);
        setSelectedAssessments([]);
        return {};
      default:
        console.log('No action provided');
        return state;
    }
  }

  const [filter, filterDispatch] = useReducer(filterReducer, {}, () => {
  });

  const sortedAssessments = useMemo(() => {
    return assessments.sort((a, b) => {
      const sort = sortDirection === 'asc' ? 1 : -1;
      let valueA;
      let valueB;
      let comparator;

      // 1. Get the field and do any pre-modifications if needed
      if (sortColumn === 'remainingTasks') {
        valueA = a['totalTasks'] - a['completedTasks'];
        valueB = b['totalTasks'] - b['completedTasks'];
      } else {
        valueA = a[sortColumn];
        valueB = b[sortColumn];
      }

      // 2. Figure out the comparator type
      // Sort by alphabetical
      if (sortColumn === 'status' ||
        sortColumn === 'testingGroup' ||
        sortColumn === 'teacher' ||
        sortColumn === 'lastName' ||
        sortColumn === 'username'
      ) {
        comparator = 'string';
      } else if (sortColumn === 'isLocked') {
        // Sort by boolean
        comparator = 'boolean';
      } else if (
        sortColumn === 'studentId' ||
        sortColumn === 'completedTasks'
      ) {
        // Sort numeric
        comparator = 'number';
      } else if (sortColumn === 'lastUpdated') {
        // Sort date
        comparator = 'date';
      }

      // 3. Do the sorting
      if (comparator === 'string') {
        valueA = valueA || '';
        return valueA.localeCompare(valueB) * sort;
      } else if (comparator === 'number' || comparator === 'boolean') {
        return (valueA - valueB) * sort;
      } else if (comparator === 'date') {
        valueA = valueA ? new Date(valueA) : 0;
        valueB = valueB ? new Date(valueB) : 0;
        return (valueA - valueB) * sort * -1;
      } else {
        return assessments;
      }
    });
  }, [assessments, sortColumn, sortDirection]);


  /***********************
   * LIFECYCLE METHODS
   ***********************/

  useEffect(() => {
    setIsLoaded(false);

    setReloadAssessments(true);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Get the Assessments
    getAssessmentsData()
      .then((assessments) => {
        setReloadAssessments(false);
        if (assessments) {
          const assessmentStudents = assessments.students;
          const numNotStarted = assessmentStudents.filter((assessment) => assessment.status === 'not started').length;
          const numRunning = assessmentStudents.filter((assessment) => assessment.status === 'running').length;
          const numDone = assessmentStudents.filter((assessment) => assessment.status === 'done').length;
          setAssessmentStatusCounts({ notStarted: numNotStarted, running: numRunning, done: numDone });
          setIsLoaded(true);
          setAssessments(assessmentStudents);
        }
      })
      .finally(() => {
        setIsLoaded(true);
      });

    // eslint-disable-next-line
  }, [reloadAssessments]);


  /***********************
   * HTTP METHODS
   ***********************/

  /**
   * Get the assessments for the authenticated school
   *
   * @return {Promise<AssessmentData>}
   */
  async function getAssessmentsData() {
    const apiPath = `${process.env.REACT_APP_API_PATH}/assessments`;
    const token = await getTokenSilently();
    const schoolIds = await getSchoolIds(token);

    const headers = new Headers({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
      'X-School-Ids': schoolIds,
    });

    if (API_DEBUG) {
      console.debug('getAssessmentsData headers', apiPath, headers);
    }
    return fetch(apiPath, {
      headers: headers,
    })
      .then((response) => {
        if (response.status === 200) {
          const json = response.json();
          if (API_DEBUG) {
            console.debug('getAssessmentsData response', json);
          }
          return json;
        } else if (response.status === 403 || schoolIds === '') {
          flashMessageContext.showMessage(`You do not have a School ID assigned to you. Please contact ${process.env.REACT_APP_SUPPORT_EMAIL} for assistance.`);
        } else if (response.status === 404) {
          flashMessageContext.showMessage('There are no assessments that are underway at this time.', 'warning');
        } else {
          flashMessageContext.showMessage(`Status Code: ${response.status} Could not fetch assessments`);
        }
      })
      .catch((error) => {
        console.error(error);
        flashMessageContext.showMessage(error.message);
      });
  }

  /**
   * Update the assessments' lock status
   */
  async function updateAssessmentsStatus(action, assessmentIds) {
    const apiPath = `${process.env.REACT_APP_API_PATH}/assessments/lock`;
    const token = await getTokenSilently();
    const schoolIds = await getSchoolIds(token);

    return fetch(apiPath, {
      method: 'PATCH',
      body: JSON.stringify({ action: action, assessmentIds: assessmentIds }),
      headers: new Headers({
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
        'X-School-Ids': schoolIds,
      }),
    })
      .then((response) => {
        if (response.status !== 204) {
          flashMessageContext.showMessage(`Status code: ${response.status}. Could not update the status of assessment.`);
        }
      })
      .catch((error) => {
        console.error(error);
        flashMessageContext.showMessage(error.message);
      });
  }


  /** *********************
   * EVENT HANDLERS
   ***********************/

  function handleSelectRowClicked(event) {
    const assessmentId = event.target.value;
    setAllRowsSelected(false);
    if (event.target.checked) {
      setSelectedAssessments([...selectedAssessments, parseInt(assessmentId)]);
    } else {
      const filteredRows = selectedAssessments.filter((rowId) => {
        return rowId !== parseInt(assessmentId);
      });
      setSelectedAssessments(filteredRows);
    }
  }

  function handleSelectAllRows(event) {
    if (event.target.checked) {
      setAllRowsSelected(true);
      setSelectedAssessments(assessments.map(((assessment) => {
        return assessment.id;
      })));
    } else {
      setAllRowsSelected(false);
      setSelectedAssessments([]);
    }
  }

  async function handleLockAssessments() {
    await updateAssessmentsStatus('lock', selectedAssessments);
    setSelectedAssessments(assessments.map(((assessment) => {
      return assessment.locked ? assessment.id : null;
    })));
    setReloadAssessments(true);
    setAllRowsSelected(false);
  }

  async function handleUnlockAssessments() {
    await updateAssessmentsStatus('unlock', selectedAssessments);
    setSelectedAssessments([]);
    setReloadAssessments(true);
    setAllRowsSelected(false);
  }

  function handleSortColumns(column) {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  }


  function handleResetFilter() {
    filterDispatch({
      type: 'RESET_FILTER',
    });

    const selects = [...document.getElementsByTagName('select')];
    return selects.map((options) => {
      return options[0].selected = true;
    });
  }


  /***********************
   * RENDER METHODS
   ***********************/


  /***********************
   * Status Header
   ***********************/
  function renderAssessmentStatusCounts() {
    return (
      <>
        <Row className='my-3 justify-content-md-center text-muted text-center'>
          <Col>
            <h5 className='assessmentStatusTitle'>Assessment Status Overview</h5>
            <span className="fw-bolder">Inactive: </span> {assessmentStatusCounts.notStarted}
            <span className="fw-bolder"> | Active: </span> {assessmentStatusCounts.running}
            <span className="fw-bolder"> | Completed: </span> {assessmentStatusCounts.done}
          </Col>
        </Row>
      </>
    );
  }


  /***********************
   * RENDER Filter Form
   ***********************/
  function renderFilter() {
    // Dropdowns for Testing Group, and Statuses
    const testingGroups = [...new Set(assessments.map((assessment) => assessment.testingGroup))];
    const testingGroupOptions = () => {
      const options = [<option key={'allGroups'}>All</option>];
      testingGroups.forEach((option) => {
        options.push(<option key={option} value={option}>{convertToTitleCase(option)}</option>);
      });
      return options;
    };

    const teachers = [...new Set(assessments.map((assessment) => assessment.teacher))];
    const teacherOptions = () => {
      const options = [<option key={'allTeachers'}>All</option>];
      teachers.sort().forEach((option) => {
        options.push(<option key={option} value={option}>{convertToTitleCase(option)}</option>);
      });
      return options;
    };

    function convertToTitleCase(str) {
      if (!str) {
        return '';
      }
      return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
    }

    const statuses = [...new Set(assessments.map((assessment) => assessment.status))];
    const statusOptions = () => {
      const options = [<option key={'allStatuses'}>All</option>];
      statuses.forEach((option) => {
        options.push(<option key={option} value={option}>{convertToTitleCase(option)}</option>);
      });
      return options;
    };

    return (
      <Form className="filter bg-secondary-subtle p-3">
        <Row>
          <Col>
            <Form.Group controlId='filterForm.testingGroupSelect'>
              <Form.Label>Testing Group</Form.Label>
              <Form.Select onChange={(event) => filterDispatch({
                type: 'SET_TESTING_GROUP_FILTER',
                value: event.target.value,
              })}>
                {testingGroupOptions()}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col >
            <Form.Group controlId='filterForm.teacherSelect'>
              <Form.Label>Teacher</Form.Label>
              <Form.Select onChange={(event) => filterDispatch({
                type: 'SET_TEACHER_FILTER',
                value: event.target.value,
              })}>
                {teacherOptions()}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='filterForm.statusSelect'>
              <Form.Label>Assessment Status</Form.Label>
              <Form.Select onChange={(event) => filterDispatch({
                type: 'SET_STATUS_FILTER',
                value: event.target.value,
              })}>
                {statusOptions()}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='filterForm.isLockedSelect'>
              <Form.Label>Locked? {renderInfoToolTip(
                'You may lock assessments for a group of students to ensure they cannot start' +
                    ' their assessment. This is useful when you are preparing for a test. ' +
                    ' Unlock when ready to start the assessment. ' +
                    ' When students or a group of students complete their exam, or complete for the session you may ' +
                    ' lock the assessment to prevent further changes. ',
                'Lock/Unlock Assessment',
                'left',
              )}
              </Form.Label>
              <Form.Select
                onChange={(event) => filterDispatch({ type: 'SET_IS_LOCKED_FILTER', value: event.target.value })}>
                <option>All</option>
                <option value='yes'>Yes</option>
                <option value='no'>No</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <Button onClick={() => handleResetFilter()}>
                  Clear Filter
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }

  /***********************
  * Lock/Unlock Buttons
  ************************/
  function renderLockButtons() {
    return (
      <Col className="col-4 px-0 mt-3 mb-2">
        <Button className="me-2" disabled={selectedAssessments.length === 0} onClick={handleLockAssessments}>
          Lock
        </Button>
        <Button disabled={selectedAssessments.length === 0} onClick={handleUnlockAssessments}>
          Unlock
        </Button>{renderInfoToolTip('Select one or more checkboxes on the left of student record, then select' +
        ' lock,or unlock to change the status of the assessment. ', 'Lock/Unlock Assessment', 'right')}
      </Col>
    );
  }

  /***********************
   * Status Icons
   *********************/
  function renderStatusIcon(status) {
    let statusIcon;
    let statusText;
    let statusColor;
    switch (status) {
      case 'not started':
        statusIcon = 'oi-circle-x';
        statusText = 'Not Started';
        statusColor = 'text-danger';
        break;
      case 'running':
        statusIcon = 'oi-play-circle';
        statusText = 'Running';
        statusColor = 'text-warning';
        break;
      case 'done':
        statusIcon = 'oi-circle-check';
        statusText = 'Done';
        statusColor = 'text-success';
        break;
      default:
        statusIcon = 'oi-x';
        statusText = 'Unknown';
        statusColor = 'text-danger';
        break;
    }
    return (
      <span
        className={`oi ${statusIcon} ${statusColor}`}
        title={statusText}
        aria-hidden={'true'}
      />
    );
  }

  /***********************
   * Locked Icons
   *********************/
  function renderIsLocked(isLocked) {
    if (isLocked) {
      return (
        <span
          className={'oi oi-lock-locked text-danger'}
          title={'Locked'}
          aria-hidden={'true'}
        />
      );
    } else {
      return (
        <span
          className={'oi oi-lock-unlocked text-success'}
          title={'Unlocked'}
          aria-hidden={'true'}
        />
      );
    }
  }

  /***********************
   * Assessment Table
   ************************/
  /***********************
   * Assessment Table Elements
   ************************/
  function renderSortableTableHeaderField(fieldName, displayName = null) {
    if (!displayName) {
      displayName = fieldName;
    }

    let sortIcon;
    let emphasis;

    if (sortColumn === fieldName) {
      sortIcon = sortDirection === 'asc' ? 'oi oi-arrow-circle-top' : 'oi oi-arrow-circle-bottom';
      emphasis = 'text-warning';
    } else {
      sortIcon = 'oi oi-elevator';
      emphasis = 'text-secondary';
    }

    return (
      <th onClick={() => handleSortColumns(fieldName)} style={{ whiteSpace: 'nowrap' }}>
        <span >
          {displayName}
        </span>
        <span className={`ps-1 ${emphasis} oi ${sortIcon}`}/>
      </th>
    );
  }

  function renderTableHeader() {
    return (
      <thead>
        <tr style={{ cursor: 'pointer' }}>
          <td><FormCheck onChange={handleSelectAllRows} checked={allRowsSelected} /></td>
          {renderSortableTableHeaderField('isLocked', 'Lock')}
          {renderSortableTableHeaderField('lastName', 'Name')}
          {renderSortableTableHeaderField('username', 'Username')}
          {renderSortableTableHeaderField('studentId', 'Password')}
          {renderSortableTableHeaderField('completedTasks', 'Tasks Done')}
          {renderSortableTableHeaderField('status', 'Status')}
          {renderSortableTableHeaderField('lastUpdated', 'Last Update')}
          {renderSortableTableHeaderField('testingGroup', 'Group')}
          {renderSortableTableHeaderField('teacher', 'Teacher')}
        </tr>
      </thead>
    );
  }

  function renderAssessmentRows() {
    const rows = [];

    sortedAssessments.forEach((assessment) => {
      // Format fields
      const isLockedText = assessment.isLocked ? 'yes' : 'no';

      function renderLastUpdatedDate() {
        if (assessment.lastUpdated) {
          const lastUpdatedDate = new Date(assessment.lastUpdated);
          return (
            <span data-bs-toggle="tooltip" title={lastUpdatedDate.toDateString()}>
              {getRelativeTimeString(lastUpdatedDate)}
            </span>
          );
        }
      }

      function renderStudentName() {
        return (
          <span>
            {assessment.firstName} {assessment.lastName}
          </span>
        );
      }

      if (filter) {
        // Filter rows
        if (filter.testingGroup && assessment.testingGroup && assessment.testingGroup !== filter.testingGroup) {
          return;
        }
        if (filter.teacher && assessment.teacher !== filter.teacher) {
          return;
        }
        if (filter.status && assessment.status !== filter.status) {
          return;
        }
        if (filter.isLocked && isLockedText !== filter.isLocked) {
          return;
        }
      }

      rows.push(<tr className={'dataRow'} key={assessment.id}>
        <td>
          <FormCheck
            value={assessment.id}
            onChange={handleSelectRowClicked}
            checked={selectedAssessments.includes(assessment.id)}
          />
        </td>
        <td className="tableRowDataAlignment">{renderIsLocked(assessment.isLocked)}</td>
        <td>{renderStudentName()}</td>
        <td>{assessment.username}</td>
        <td className="tableRowDataAlignment">{assessment.studentId}</td>
        <td className="tableRowDataAlignment">{assessment.completedTasks} of {assessment.totalTasks}</td>
        <td>{renderStatusIcon(assessment.status)}</td>
        <td className="tableRowDataAlignment">{renderLastUpdatedDate()}</td>
        <td className="tableRowDataAlignment">{assessment.testingGroup}</td>
        <td>{assessment.teacher}</td>
      </tr>);
    });

    return rows;
  }

  function renderTable() {
    return (
      <Table striped bordered hover size="sm" className="px-0" style={{ maxHeight: '26rem' }}>
        {renderTableHeader()}
        <tbody>
          {renderAssessmentRows()}
        </tbody>
      </Table>
    );
  }

  /** *********************
  * Main render method
  */
  if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        {renderSubheader()}
        <div>
          <Row>
            {renderAssessmentStatusCounts()}
          </Row>
          <Row className="mx-4">
            {renderFilter()}
          </Row>
          <Row className="mx-4">
            {renderLockButtons()}
          </Row>
          <Row className="mx-4">
            {renderTable()}
          </Row>
        </div>
      </>
    );
  }
};

/***********************
 * JSDOC DEFINITIONS
 ***********************/

/**
 * @typedef AssessmentData
 * @property {string} testingGroup
 * @property {string} teacher
 * @property {string} lastName
 * @property {string} firstName
 * @property {number} age
 * @property {number} studentId
 * @property {number} completedTasks
 * @property {string} currentTask
 * @property {number} totalTasks
 * @property {Date} lastUpdated
 */

export default Dashboard;
