import React, { useContext, useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { Required, renderInfoToolTip } from './common';
import { useAuth0 } from '../react-auth0-wrapper';
import { getSchoolIds } from './FetchData';
import { FlashMessageContext } from '../context/flash-message';
import api from '../api';
import PropTypes from 'prop-types';

const UserManagement = ({ users, addUser, removeUser, isLoading, setIsLoading, loadUsers }) => {
  const DEFAULT_FORM_DATA = {
    email: '',
    name: '',
    isAdmin: false,
  };
  const { user: auth0User, getTokenSilently } = useAuth0();
  const [formData, setFormData] = useState(DEFAULT_FORM_DATA);
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortColumn, setSortColumn] = useState('email');
  const [showModal, setShowModal] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const flashMessageContext = useContext(FlashMessageContext);

  const openModal = () => {
    if (!showModal) {
      setShowModal(true);
    }
  };

  const closeModal = () => setShowModal(false);

  if (isLoading) {
    return <Spinner />;
  }

  const remove = async (event, user) => {
    event.preventDefault();
    const ans = window.confirm(`Are you sure you want to remove ${user.email}?`);
    if (ans) {
      const token = await getTokenSilently();
      const schoolIds = await getSchoolIds(token);
      const endpoint = `/admin/users`;

      setIsLoading(true);
      api.delete(
        endpoint,
        { email: user.email },
        { 'X-School-Ids': schoolIds },
      ).then((response) => {
        switch (response.status) {
          case 204:
            flashMessageContext.showMessage('User deleted.', 'success');
            break;
          case 400:
          case 500:
            flashMessageContext.showMessage('Delete failed.');
            break;
          default:
            flashMessageContext.showMessage(`Response Status ${response.status}`);
        }
        removeUser(user);
        setIsLoading(false);
        return response.text();
      }).then((text) => console.log(text));
      clearUser();
    }
  };

  const createUser = () => {
    setNewUser(true);
    openModal();
  };

  const edit = (user) => {
    setNewUser(false);
    openModal();

    if (showModal) updateRoleField(user.is_portal_admin);
    setFormData({
      email: user.email,
      name: user.name,
      isAdmin: user.is_portal_admin,
    });
  };

  const updateFormField = (field, value) => {
    if (field === 'email' && value) {
      value = value.toLowerCase();
    }
    const updated = { ...formData, [field]: value };
    setFormData(updated);
  };

  const updateRoleField = (value) => {
    const radios = document.getElementsByName('role-radio');
    radios.forEach((radio) => {
      radio.checked = false;
    });

    if (value) {
      const admin = document.getElementById('role-radio-admin');
      admin.checked = true;
      setFormData({ ...formData, isAdmin: true });
    } else {
      const proctor = document.getElementById('role-radio-proctor');
      proctor.checked = true;
      setFormData({ ...formData, isAdmin: false });
    }
  };

  const clearUser = () => {
    setFormData(DEFAULT_FORM_DATA);
    if (showModal) closeModal();
  };

  const alertEmailSending = (event, student) => {
    event.preventDefault();
    const message = `Sending invitation email to ${student.email}.`;
    const dismiss = true;
    flashMessageContext.showMessage(message, 'warning', dismiss);
    sendInvitationEmail(student);
  }


  const formInvalid = () => !formData.email || !formData.name;

  const saveUser = async () => {
    if (formInvalid()) {
      flashMessageContext.showMessage('Please fill in required fields before saving.');
    } else {
      const token = await getTokenSilently();
      const schoolIds = await getSchoolIds(token);
      const endpoint = `/admin/users`;

      setIsLoading(true);
      api.post(
        endpoint,
        formData,
        { 'X-School-Ids': schoolIds },
      )
        .then((updatedUser) => {
          setIsLoading(false);
          addUser(updatedUser);
          loadUsers();
        })
        .catch((error) => {
          setIsLoading(false);
          console.log('ERROR: ', error);
          flashMessageContext.showMessage(`Error: Failed to save user.`);
        });

      clearUser();
    }
  };

  const sendInvitationEmail = async (user) => {
    const token = await getTokenSilently();
    const schoolIds = await getSchoolIds(token);
    const endpoint = `/admin/users/invitation_email`;
    const email = user.email;

    api.rawPost(
      endpoint,
      { email },
      { 'X-School-Ids': schoolIds },
    ).then(response => {
      switch (response.status) {
        case 200:
          const messageExpires = true;
          flashMessageContext.showMessage('Invitation email sent.', 'success', messageExpires);
          break;
        case 400:
          flashMessageContext.showMessage(`User ${email} not found!`)
          break;
        case 500:
          flashMessageContext.showMessage('Something went wrong on our side. Try again.');
          break;
        default:
          console.log("Something weird happened with sending invitation email.")
          break;
      }
    })
  }

  const disabledRoleChange = () => {
    return auth0User.email === formData.email;
  };

  function renderTableHeader() {
    return (
      <thead className='tableHeaderRow'>
        <tr style={{ cursor: 'pointer' }}>
          <th className="text-start">Edit&nbsp;/&nbsp;Remove</th>
          {renderSortableTableHeaderField('name', 'Name')}
          {renderSortableTableHeaderField('email', 'Email')}
          {renderSortableTableHeaderField('is_portal_admin', 'Role')}
          {renderSortableTableHeaderField('login_count', 'Login Count')}
        </tr>
      </thead>
    );
  }

  function handleSortColumns(column) {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  }

  function renderSortableTableHeaderField(fieldName, displayName = null) {
    if (!displayName) {
      displayName = fieldName;
    }

    let sortIcon;
    let emphasis;

    if (sortColumn === fieldName) {
      sortIcon = sortDirection === 'asc' ? 'oi oi-arrow-circle-top' : 'oi oi-arrow-circle-bottom';
      emphasis = 'text-warning';
    } else {
      sortIcon = 'oi oi-elevator';
      emphasis = 'text-secondary';
    }

    return (
      <th onClick={() => handleSortColumns(fieldName)} style={{ whiteSpace: 'nowrap' }}>
        <span>
          {displayName}
        </span>
        <span className={`ps-1 ${emphasis} oi ${sortIcon}`} />
      </th>
    );
  }


  const sortRows = (studentA, studentB) => {
    let sorted;
    switch (typeof studentA[sortColumn]) {
      case 'string':
        sorted = studentA[sortColumn].localeCompare(studentB[sortColumn]);
        break;
      case 'number':
        sorted = studentA[sortColumn] - studentB[sortColumn];
        break;
      case 'boolean':
        sorted = (studentB[sortColumn] ? 1 : 0) - (studentA[sortColumn] ? 1 : 0);
        break;
      default:
        sorted = 0;
    }
    if (sortDirection === 'desc') sorted = -sorted;
    return sorted;
  };

  const editFormModal = () => {
    const action = newUser ? 'Add' : 'Update';
    return (
      <Modal
        show={showModal}
        onHide={closeModal}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop='static'
      >
        <Modal.Header closeButton>
          <Modal.Title>{action} Administrative User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className={'bg-secondary-subtle px-4 pt-4 mb-3'}>
            <p>{action} a School Test Coordinator (STC) or Proctor
              {renderInfoToolTip(
                'Administrative users can be either School Test Coordinators (STCs) or Proctors. ' +
                'When you add a new user, or update the email address of an existing user, they\'ll get an email ' +
                'notification with a link to the portal. They will need to set their password, and agree to the ' +
                'terms of service in order to log in.',
                'STC and Proctor Info',
              )}</p>
            <Row className="col-12 m-0 mb-3 border border-secondary-subtle">
              <Form.Group as={Col} className="mb-3" controlId="formName">
                <Form.Label sm={2}>
                  <Required>Full name</Required>
                </Form.Label>
                <Col lg={10}>
                  <Form.Control
                    type="text"
                    value={formData.name || ''}
                    onChange={(e) => updateFormField('name', e.target.value)}
                  />
                </Col>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label sm={2}>
                  <Required>Email</Required>
                </Form.Label>
                <Col lg={10}>
                  <Form.Control
                    type="text"
                    value={formData.email || ''}
                    onChange={(e) => updateFormField('email', e.target.value)}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Col} className="mb-4" controlId="formRole">
                <Form.Label sm={2}>
                  <Required>Role</Required>
                  {renderInfoToolTip(
                    'School Test Coordinators (STCs) can manage system users, create, edit, and delete students, ' +
                    'globally lock and unlock the school-wide assessment, and view score reports, as well as perform ' +
                    'the same functions as proctors. Proctors use the system only to monitor the assessment in real-time ' +
                    'and locally lock and unlock one or several students\' access to the assessment.',
                    'Role Info',
                  )}
                </Form.Label>
                <Col lg={12}>
                  <Form.Check
                    type="radio"
                    id="role-radio-admin"
                    label="School Test Coordinator (STC)"
                    name="role-radio"
                    checked={!!formData.isAdmin}
                    disabled={disabledRoleChange()}
                    onChange={() => updateRoleField(true)}
                  />
                  <Form.Check
                    type="radio"
                    id="role-radio-proctor"
                    label="Proctor"
                    name="role-radio"
                    checked={!formData.isAdmin}
                    disabled={disabledRoleChange()}
                    onChange={() => updateRoleField(false)}
                  />
                </Col>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} className="mb-3" controlId="formSaveButton">
                <Col style={{ textAlign: 'right' }}>
                  <Button className="me-2" variant="secondary" name="cancelButton" onClick={clearUser}>
                    Cancel
                  </Button>
                  <Button variant="primary" name="saveButton" onClick={saveUser}>
                    Save
                  </Button>
                </Col>
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <section>
      <p>Logged in user: <span style={{ fontWeight: 'bold' }}>{auth0User.email}</span></p>
      {editFormModal()}
      <Button onClick={createUser} className="mb-3">Add New STCs & Proctors</Button>
      <Table striped bordered hover>
        {renderTableHeader()}
        <tbody>
          {users.sort((a, b) => sortRows(a, b)).map((user) => {
            return (
              <tr id={user.user_id} key={user.user_id}>
                <td>
                  {/* eslint-disable-next-line */}
                  <a href='#' onClick={(e) => remove(e, user)}>
                    <span className='oi oi-delete' title='remove' aria-hidden='true'></span>
                  </a>&nbsp;|&nbsp;
                  {/* eslint-disable-next-line */}
                  <a href='#' onClick={() => edit(user)}>
                    <span className='oi oi-pencil' title='edit' aria-hidden='true'></span>
                  </a>
                  &nbsp;|&nbsp;
                  {/* eslint-disable-next-line */}
                  {renderInfoToolTip(
                    'Resend invitation email to user',
                    'Info', 'top',
                    <a href='#' onClick={(e) => alertEmailSending(e, user)}>
                      <span className='oi oi-envelope-closed' title='email' aria-hidden='true'></span>
                    </a>
                  )}
                </td>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>
                  {user.is_portal_admin ? 'STC' : 'Proctor'}
                </td>
                <td>{user.login_count}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </section>
  );
};

UserManagement.propTypes = {
  users: PropTypes.array,
  addUser: PropTypes.func,
  removeUser: PropTypes.func,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  loadUsers: PropTypes.func,
};

export default UserManagement;
