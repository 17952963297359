import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { Accordion, Button, Col, Container, Form, Modal, Row, Table, Spinner } from 'react-bootstrap';

import { useAuth0 } from '../react-auth0-wrapper';
import { getSchoolIds } from './FetchData';
import { Required, renderInfoToolTip } from './common';
import { FlashMessageContext } from '../context/flash-message';
import api from '../api';
import { passiveSupport } from 'passive-events-support/src/utils';

import '../styles/students.css';

export function pauseUnpauseMessageInfo(pausedState) {
  const pauseUnpausePrefix = pausedState ? 'Unlock' : 'Lock';
  const pauseUnpauseSuffix = ' Assessment School-wide';
  function pauseVariant(pausedState) {
    return pausedState ? 'warning' : 'success';
  }
  return {
    button_text: pauseUnpausePrefix + pauseUnpauseSuffix,
    flash_text: pauseUnpausePrefix + 'ed' + pauseUnpauseSuffix,
    button_variant: pauseVariant(pausedState),
    flash_variant: pauseVariant(!pausedState),
  };
}

const StudentManagement = ({ assessmentId,
  students,
  setStudents,
  assessmentPaused,
  assessmentStatusLoading,
  pauseOrUnpauseAssessment,
}) => {
  passiveSupport({ events: ['touchstart', 'touchmove'] });

  const defaultFormValues = {
    studentId: '',
    username: '',
    assignedExam: '',
    firstName: '',
    middleName: '',
    lastName: '',
    preferredName: '',
    dateOfBirth: '',
    parentage: '',
    gender: '',
    raceEthnicity: '',
    isAdult: false,
    studentRating: '1',
    testingGroup: '',
    teacher: '',
    dateOfAdmission: '',
    languageAtHome: '',
    languageOfInstruction: '',
  };

  const [formData, setFormData] = useState(defaultFormValues);
  const { getTokenSilently } = useAuth0();

  const [sortDirection, setSortDirection] = useState('asc');
  const [sortColumn, setSortColumn] = useState('student_id');
  const [show, setShow] = useState(false);

  const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL || 'support@asledcenter.org';
  const flashMessageContext = useContext(FlashMessageContext);

  const exposureAges = ['', ...[...Array(18).keys()]];
  const fieldsWithList = {
    raceEthnicity: 'raceEthnicityList',
    languageAtHome: 'languageAtHomeList',
    languageOfInstruction: 'languageOfInstructionList'
  };
  const normalizedField = name => (
    Object.keys(fieldsWithList).find(key => fieldsWithList[key] === name)
  )

  const updateForm = (name, value) => {
    if (Object.values(fieldsWithList).includes(name)) {
      name = normalizedField(name)
    }

    setFormData((data) => ({ ...data, [name]: value }));
  };

  const edit = (studentId) => {
    const student = students.find((s) => s.student_id === studentId);
    if (!student) {
      setFormData(defaultFormValues);
    } else {
      setFormData({
        studentId: student.student_id || '',
        username: student.username || '',
        assignedExam: student.exam || '',
        firstName: student.first_name || '',
        middleName: student.middle_name || '',
        lastName: student.last_name || '',
        preferredName: student.preferred_name || '',
        dateOfBirth: student.date_of_birth || '',
        parentage: student.parentage || '',
        gender: student.gender || '',
        raceEthnicity: student.race_ethnicity || '',
        isAdult: student.is_adult || false,
        studentRating: student.student_rating || '1',
        testingGroup: student.testing_group || '',
        teacher: student.teacher || '',
        dateOfAdmission: student.date_of_admission || '',
        languageOfInstruction: student.language_of_instruction || '',
        languageAtHome: student.language_at_home || '',
        ageExposed: student.age_exposed_to_asl || '',
      });
    }
    handleShow();
  };

  const remove = (event, student) => {
    event.preventDefault();

    let fullName = student.first_name + ' ';
    if (student.middle_name) {
      fullName += student.middle_name + ' ';
    }
    fullName += student.last_name;

    const message = `Are you sure you want to remove ${fullName}?`;
    const result = window.confirm(message);
    if (result) {
      removeStudent(student);
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  function handleSortColumns(column) {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  }

  function renderSortableTableHeaderField(fieldName, displayName = null) {
    if (!displayName) {
      displayName = fieldName;
    }

    let sortIcon;
    let emphasis;

    if (sortColumn === fieldName) {
      sortIcon = sortDirection === 'asc' ? 'oi oi-arrow-circle-top' : 'oi oi-arrow-circle-bottom';
      emphasis = 'text-warning';
    } else {
      sortIcon = 'oi oi-elevator';
      emphasis = 'text-secondary';
    }

    return (
      <th onClick={() => handleSortColumns(fieldName)} style={{ whiteSpace: 'nowrap' }}>
        <span>
          {displayName}
        </span>
        <span className={`ps-1 ${emphasis} oi ${sortIcon}`} />
      </th>
    );
  }

  const sortRows = (studentA, studentB) => {
    let sorted;
    switch (typeof studentA[sortColumn]) {
      case 'string':
        sorted = studentA[sortColumn].localeCompare(studentB[sortColumn]);
        break;
      case 'number':
        sorted = studentA[sortColumn] - studentB[sortColumn];
        break;
      case 'boolean':
        sorted = (studentB[sortColumn] ? 1 : 0) - (studentA[sortColumn] ? 1 : 0);
        break;
      default:
        sorted = 0;
    }
    if (sortDirection === 'desc') sorted = -sorted;
    return sorted;
  };

  function renderPauseAssessmentToggle() {
    const pauseVariant = pauseUnpauseMessageInfo(assessmentPaused).button_variant;
    const buttonText = pauseUnpauseMessageInfo(assessmentPaused).button_text;
    const pausedInfoText = 'The assessment is currently locked school-wide, for all students.  ' +
      ' Students may not log in.  A school-wide lock is a ' +
      'good step to take after school hours, or other times that no test is actively underway. When you are ready to start ' +
      'testing again, click "Release Assessment School-wide".';
    const runningInfoText = 'The assessment is currently unlocked; proctors and others may initiate testing.  ' +
      'After the session is complete for the day, it\'s a good idea to re-apply the school-wide lock as a security measure to ensure that students ' +
      'cannot log in without suitable monitors and controls in place. Please note that proctors may also lock individual students\' assessments ' +
      'so both school-wide and individual locks must be released.  The proctor dashboard will show any individual locks that are in place.';


    return (
      <>
        <Container className="ms-0 ps-0">
          <Row className="my-3 col-12">
            <Col className="col-4">
              <Button onClick={pauseOrUnpauseAssessment} variant={pauseVariant}>
                {(assessmentStatusLoading && <Spinner />) || (buttonText)}
                {renderInfoToolTip(
                  !assessmentStatusLoading && assessmentPaused ? pausedInfoText : runningInfoText,
                  'School-wide Lock Info',
                  'bottom',
                )}
              </Button>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  function renderTableHeader() {
    return (
      <thead className="tableHeaderRow">
        <tr style={{ cursor: 'pointer' }}>
          <th className="text-start">Edit&nbsp;/&nbsp;Remove</th>
          {renderSortableTableHeaderField('student_id', 'Student ID')}
          {renderSortableTableHeaderField('username', 'Username')}
          {renderSortableTableHeaderField('first_name', 'First Name')}
          {renderSortableTableHeaderField('last_name', 'Last Name')}
          {renderSortableTableHeaderField('date_of_birth', 'Age')}
          {renderSortableTableHeaderField('parentage', 'Parentage')}
          {renderSortableTableHeaderField('gender', 'Gender')}
          {renderSortableTableHeaderField('race_ethnicity', 'Race/Ethnicity')}
          {renderSortableTableHeaderField('student_rating', 'Rating')}
          {renderSortableTableHeaderField('date_of_admission', 'Date of Admission')}
          {renderSortableTableHeaderField('language_at_home', 'Language at Home')}
          {renderSortableTableHeaderField('age_exposed_to_asl', 'Age Exposed to ASL')}
          {renderSortableTableHeaderField('language_of_instruction', 'Language of Instruction')}
          {renderSortableTableHeaderField('teacher', 'Teacher')}
          {renderSortableTableHeaderField('testing_group', 'Testing Group')}
          {renderSortableTableHeaderField('exam', 'Assigned Exam')}
        </tr>
      </thead>
    );
  }

  function formatExamText(exam) {
    if (exam) {
      switch (exam) {
        case 'AGES_0to7_v2017':
          return 'Up to 7 years old';
        case 'AGES_8to12_v2017':
          return '8 to 12 years old';
        case 'AGES_13plus_v2017':
          return '13 years old and older';
        default:
          return exam;
      }
    }
    return 'Test not assigned';
  }

  function renderStudentRows() {
    if (!students) {
      return null;
    }
    return students.sort((a, b) => sortRows(a, b)).map((student) => {
      if (!student) {
        return null;
      }

      return (
        <tr key={student.id} id={student.id} className='student'>
          <td style={{ textAlign: 'right' }}>
            {/* eslint-disable-next-line */}
            <a href='#' onClick={() => edit(student.student_id)}>
              <span className='oi oi-pencil' title='edit' aria-hidden='true'></span>
            </a>
            &nbsp;|&nbsp;
            {/* eslint-disable-next-line */}
            <a href='#' onClick={(e) => remove(e, student)}>
              <span className='oi oi-delete' title='remove' aria-hidden='true'></span>
            </a>
          </td>
          <td>{student.student_id}</td>
          <td>{student.username}</td>
          <td>{student.first_name}</td>
          <td>{student.last_name}</td>
          <td>{student.age}</td>
          <td>{student.parentage}</td>
          <td>{student.gender ? student.gender.toUpperCase() : ''}</td>
          <td>{student.race_ethnicity}</td>
          <td>{student.student_rating}</td>
          <td>{student.date_of_admission}</td>
          <td>{student.language_at_home}</td>
          <td>{student.age_exposed_to_asl}</td>
          <td>{student.language_of_instruction}</td>
          <td>{student.teacher}</td>
          <td>{student.testing_group}</td>
          <td>{formatExamText(student.exam)}</td>
        </tr>
      );
    });
  }

  function renderTable() {
    return (
      <div className='tableDiv'>
        <Container className='ms-0 ps-0 pt-4'>
          <Row>
            <Col xs={3}>
              <Button className="mb-3" variant={'primary'} onClick={() => edit('')}>
                Add New Student
              </Button>
            </Col>
            <Col xs={9}></Col>
          </Row>
        </Container>
        <Table striped bordered hover style={{ margin: 0 }}>
          {renderTableHeader()}
          <tbody>
            {renderStudentRows()}
          </tbody>
        </Table>
      </div>
    );
  }

  function renderEditForm() {
    const editMode = formData.studentId !== '' && formData.studentId !== undefined;
    return (
      <Modal
        show={show}
        onHide={handleClose}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop='static'
      >

        <Modal.Header closeButton>
          <Modal.Title>{editMode ? 'Edit' : 'Add New'} Student</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <p><span style={{ color: 'red' }}>*</span>&nbsp;Required Fields</p>
            {editMode &&
              <Row className='mb-1'>
                <Form.Group as={Col} className='mb-1' controlId='formStudentId'>
                  <Form.Label sm={1}>
                    ID
                  </Form.Label>
                  <Col sm={12}>
                    <Form.Control
                      type='text'
                      disabled
                      value={formData.studentId} />
                  </Col>
                </Form.Group>

                <Form.Group as={Col} className='mb-1' controlId='formUsername'>
                  <Form.Label sm={1}>
                    Username
                  </Form.Label>
                  <Col sm={12}>
                    <Form.Control
                      type='text'
                      disabled
                      value={formData.username}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Col} className='mb-1' controlId='formAssignedExam'>
                  <Form.Label sm={1}>
                    Assigned Exam
                  </Form.Label>
                  <Col sm={12}>
                    <Form.Control
                      type='text'
                      disabled
                      value={formatExamText(formData.assignedExam)}
                    />
                  </Col>
                </Form.Group>
              </Row>
            }

            <Row className='mb-1'>
              <Form.Group as={Col} className='mb-1' controlId='formFirstName'>
                <Form.Label sm={1}>
                  <Required>First Name</Required>
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type='text'
                    name='firstName'
                    value={formData.firstName || ''}
                    onChange={(e) => updateForm(e.target.name, e.target.value)}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Col} className='mb-1' controlId='formMiddleName'>
                <Form.Label sm={1}>
                  Middle
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type='text'
                    name='middleName'
                    value={formData.middleName || ''}
                    onChange={(e) => updateForm(e.target.name, e.target.value)}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Col} className='mb-1' controlId='formLastName'>
                <Form.Label sm={1}>
                  <Required>Last Name</Required>
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type='text'
                    name='lastName'
                    value={formData.lastName || ''}
                    onChange={(e) => updateForm(e.target.name, e.target.value)}
                  />
                </Col>
              </Form.Group>
            </Row>

            <Row className='mb-1'>
              <Form.Group as={Col} className='mb-1' controlId='formPreferredName'>
                <Form.Label sm={1}>
                  Preferred Name
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type='text'
                    name='preferredName'
                    value={formData.preferredName || ''}
                    onChange={(e) => updateForm(e.target.name, e.target.value)}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Col} className='mb-1' controlId='formDateOfBirth'>
                <Form.Label sm={1}>
                  <Required>Date of Birth</Required>
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type='date'
                    placeholder={'mm/dd/yyyy'}
                    name='dateOfBirth'
                    value={formData.dateOfBirth || ''}
                    onChange={(e) => updateForm(e.target.name, e.target.value)}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Col} className='mb-1' controlId='formLastName'>
                <Form.Label sm={1}>
                  <Required>Primary Parent/Guardian</Required>
                  {renderInfoToolTip(
                    'Please provide the primary language of the person who interacts with the student most at home. ' +
                    'For Hard of Hearing parent/guardian, please select Deaf Parent.'
                  )}
                </Form.Label>
                <Col sm={12}>
                  <Form.Select
                    aria-label="Select Primary Parent/Guardian"
                    name="parentage"
                    value={formData.parentage || ''}
                    onChange={(e) => updateForm(e.target.name, e.target.value)}
                  >
                    <option value=""></option>
                    <option value="DP">Deaf Parent</option>
                    <option value="HP">Hearing Parent</option>
                  </Form.Select>
                </Col>
              </Form.Group>
            </Row>

            <Row className="form-breaker">
              <hr />
            </Row>

            <Row className='mb-1'>
              <Form.Group as={Col} className='mb-1' controlId='formGender'>
                <Form.Label sm={1}>
                  <Required>Gender</Required>
                </Form.Label>
                <Col sm={12}>
                  <Form.Select
                    aria-label='Gender select menu'
                    name='gender'
                    value={formData.gender || ''}
                    onChange={(e) => updateForm(e.target.name, e.target.value)}
                  >
                    <option value=''></option>
                    <option value='m'>Male</option>
                    <option value='f'>Female</option>
                    <option value='n'>Non-binary</option>
                  </Form.Select>
                </Col>
              </Form.Group>

              <Form.Group as={Col} className='mb-1' controlId='formRaceEthnicity'>
                <Form.Label sm={1}>
                  <Required>Race / Ethnicity</Required>
                  {renderInfoToolTip('Select the student\'s race or ethnicity from the dropdown list below, or if not listed, type into the field.')}
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type='text'
                    name='raceEthnicity'
                    placeholder={'Select or type'}
                    value={formData.raceEthnicity || ''}
                    onChange={(e) => updateForm(e.target.name, e.target.value)}
                  >
                  </Form.Control>
                  <Form.Select
                    aria-label='Race/Ethnicity select menu'
                    name='raceEthnicityList'
                    value={formData.raceEthnicity || ''}
                    onChange={(e) => updateForm(e.target.name, e.target.value)}
                  >
                    <option value=''></option>
                    <option value='American Indian / Alaska Native'>American Indian / Alaska Native</option>
                    <option value='Asian'>Asian</option>
                    <option value='Black or African American'>Black or African American</option>
                    <option value='Hispanic or Latino'>Hispanic or Latino</option>
                    <option value='More than one'>More than one</option>
                    <option value='Native Hawaiian or other Pacific Islander'>Native Hawaiian or other Pacific
                      Islander
                    </option>
                    <option value='White or Caucassian'>White or Caucassian</option>
                    <option value='Other / Unknown'>Other / Unknown</option>
                  </Form.Select>
                </Col>
              </Form.Group>
            </Row>

            <Row className='mb-1'>
              <Form.Group as={Col} className='mb-1' controlId='formStudentRating'>
                <Form.Label sm={1}>
                  <Required>Student Rating</Required>
                  {renderInfoToolTip('Select a rating that best describes the student\'s academic ability.' +
                    'Refer to the "rating level" descriptions on the right for guidance.')}
                </Form.Label>
                <Form.Select
                  aria-label='student rating select menu'
                  name='studentRating'
                  value={formData.studentRating || '1'}
                  onChange={(e) => updateForm(e.target.name, e.target.value)}
                >
                  <option value='1'>Rating Level 1</option>
                  <option value='2'>Rating Level 2</option>
                  <option value='3'>Rating Level 3</option>
                  <option value='4'>Rating Level 4</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} className='mb-1' controlId='formStudentRatingDesc'>
                <Form.Label sm={1}>Student Rating: Level of Student Functioning Performance</Form.Label>
                <Accordion>
                  <Accordion.Item eventKey='0'>
                    <Accordion.Header>Click to Expand</Accordion.Header>
                    <Accordion.Body className="student-rating-accordian-body">
                      <ul>
                        <li>Level 1: Student is performing as expected for their age in school.</li>
                        <li>Level 2: Student is performing below average but has not been diagnosed
                          with any disabilities that impact learning.
                        </li>
                        <li>Level 3: Student is performing below average AND is diagnosed
                          with the disability selected below:
                        </li>
                        <ul>
                          <li>ADD/ADHD</li>
                          <li>Cognitive Processing Disorder</li>
                          <li>Dyscalculia</li>
                          <li>Dysgraphia</li>
                          <li>Dyslexia</li>
                          <li>Dyspraxia</li>
                          <li>Executive Functioning</li>
                        </ul>
                        <li>Level 4: Student is performing below average AND is diagnosed
                          with the disability selected below:
                        </li>
                        <ul>
                          <li>Autistic Spectrum Disorder (ASD)</li>
                          <li>Developmental Disability</li>
                          <li>Dual Sensory Impairment</li>
                          <li>Emotional Disturbance</li>
                          <li>Intellectual Impairment</li>
                          <li>Neurological Impairment</li>
                          <li>Pervasive Development Disability (PDD)</li>
                        </ul>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Form.Group>
            </Row>

            <Row className="form-breaker">
              <hr />
            </Row>

            <Row className='mb-1'>
              <Form.Group as={Col} className='mb-1' controlId='formLanguageOfInstruction'>
                <Form.Label sm={1}>
                  <Required>Language of Instruction at School</Required>
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type='text'
                    name='languageOfInstruction'
                    placeholder={'Select or type'}
                    value={formData.languageOfInstruction || ''}
                    onChange={(e) => updateForm(e.target.name, e.target.value)}
                  >
                  </Form.Control>
                  <Form.Select
                    aria-label='Langage at Home Select Menu'
                    name='languageOfInstructionList'
                    value={formData.languageOfInstruction || ''}
                    onChange={(e) => updateForm(e.target.name, e.target.value)}
                  >
                    <option value=''></option>
                    <option value='ASL'>American Sign Language (ASL)</option>
                    <option value='Spoken English'>Spoken English</option>
                    <option value='ASL and Spoken English'>ASL and Spoken English</option>
                    <option value='other'>Other</option>
                  </Form.Select>
                </Col>
              </Form.Group>
              <Form.Group as={Col} className='mb-1' controlId='formDateOfAdmission'>
                <Form.Label sm={1}>
                  <Required>Date of Admission</Required>
                  {renderInfoToolTip(
                    'This field is asking for the date the student entered your school / program. ' +
                    "If you're not sure enter September 1 of the current school year."
                  )}
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type='date'
                    placeholder={'mm/dd/yyyy'}
                    min={formData.dateOfBirth}
                    name='dateOfAdmission'
                    value={formData.dateOfAdmission || ''}
                    onChange={(e) => updateForm(e.target.name, e.target.value)}
                  />
                </Col>
              </Form.Group>
            </Row>

            <Row className="form-breaker">
              <hr />
            </Row>

            <Row className='mb-1'>
              <Form.Group as={Col} className='mb-1' controlId='formLanguageAtHome'>
                <Form.Label sm={1}>
                  Primary Language at Home
                  {renderInfoToolTip('Select the language most often used at home with the student.')}
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type='text'
                    name='languageAtHome'
                    placeholder={'Select or type'}
                    value={formData.languageAtHome || ''}
                    onChange={(e) => updateForm(e.target.name, e.target.value)}
                  >
                  </Form.Control>
                  <Form.Select
                    aria-label='Langage at Home Select Menu'
                    name='languageAtHomeList'
                    value={formData.languageAtHome || ''}
                    onChange={(e) => updateForm(e.target.name, e.target.value)}
                  >
                    <option value=''></option>
                    <option value='ASL'>American Sign Language (ASL)</option>
                    <option value='Spoken English'>Spoken English</option>
                    <option value='ASL / Another Language'>ASL / Another Language</option>
                    <option value='other'>Other</option>
                  </Form.Select>
                </Col>
              </Form.Group>
              <Form.Group as={Col} className='mb-1' controlId='formAgeExposed'>
                <Form.Label sm={1}>
                  Age First Exposed to ASL
                  {renderInfoToolTip('If unknown, enter the date this student first enrolled in an ASL signing school program.')}
                </Form.Label>
                <Col sm={12}>
                  <Form.Select
                    aria-label='Age Exposed to ASL Select Menu'
                    name='ageExposed'
                    value={formData.ageExposed || ''}
                    onChange={(e) => updateForm(e.target.name, e.target.value)}
                  >{exposureAges.map(age =>
                    <option value={age}>{age}</option>
                  )}
                  </Form.Select>
                </Col>
              </Form.Group>
            </Row>

            <Row className="form-breaker">
              <hr />
            </Row>

            <Row className='mb-1'>
              <Form.Group as={Col} className='mb-1' controlId='formTestingGroup'>
                <Form.Label sm={1}>
                  Testing Group
                  {renderInfoToolTip('Testing group lets you assign this student ' +
                    'to a group who will take the assessment together. ' +
                    'Proctors can then choose their group when monitoring progress. ' +
                    'Any group name you like can be used (i.e. name of districts, color group, grade). ' +
                    'You can also leave this blank and choose later if needed.')}
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type='text'
                    name='testingGroup'
                    value={formData.testingGroup || ''}
                    onChange={(e) => updateForm(e.target.name, e.target.value)}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Col} className='mb-1' controlId='formTeacher'>
                <Form.Label sm={1}>
                  Teacher
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type='text'
                    name='teacher'
                    value={formData.teacher || ''}
                    onChange={(e) => updateForm(e.target.name, e.target.value)}
                  />
                </Col>
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' name='cancelButton' onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='primary' name='saveButton' onClick={saveStudent}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const saveStudent = async () => {
    const {
      firstName,
      lastName,
      dateOfBirth,
      parentage,
      gender,
      raceEthnicity,
      studentRating,
      dateOfAdmission,
      languageOfInstruction,
    } = formData;
    if (!firstName || !lastName || !dateOfBirth || !parentage || !gender || !raceEthnicity || !studentRating || !dateOfAdmission || !languageOfInstruction) {
      flashMessageContext.showMessage('Please fill in required fields before saving.');
    } else {
      const token = await getTokenSilently();
      const schoolIds = await getSchoolIds(token);
      api.post(
        `/assessments/${assessmentId}/students`,
        formData,
        { 'x-school-ids': schoolIds },
      ).then((newOrUpdatedStudent) => {
        const index = students.findIndex((s) => s.id === newOrUpdatedStudent.id);
        let rest = students;
        if (index >= 0) {
          rest = [
            ...students.slice(0, index),
            ...students.slice(index + 1),
          ];
        }
        setStudents([...rest, newOrUpdatedStudent]);
        handleClose();
        window.location.href = `#${newOrUpdatedStudent.id}`;
      }).catch((error) => {
        flashMessageContext.showMessage(`Save failed: ${error}`);
        console.error(error);
      });
    }
  };

  const removeStudent = async (student) => {
    const token = await getTokenSilently();
    const schoolIds = await getSchoolIds(token);
    api.delete(
      `/assessments/${assessmentId}/students`,
      { studentId: student.student_id },
      { 'x-school-ids': schoolIds },
    ).then((response) => {
      if (response.status === 204) {
        const index = students.findIndex((s) => s.id === student.id);
        if (index >= 0) {
          setStudents([
            ...students.slice(0, index),
            ...students.slice(index + 1),
          ]);
        }
        window.alert('Student removed.');
      } else if (response.status === 400) {
        flashMessageContext.showMessage('You cannot remove a student that has already started the test.');
      } else if (response.status === 403 || schoolIds === '') {
        flashMessageContext.showMessage(`You do not have a School ID assigned to you. Please contact ${supportEmail} for assistance.`);
      }
    });
  };

  return <>
    {renderPauseAssessmentToggle()}
    {renderEditForm()}
    {renderTable()}
  </>;
};

StudentManagement.propTypes = {
  assessmentId: PropTypes.number,
  students: PropTypes.arrayOf(PropTypes.shape({
    student_id: PropTypes.number.isRequired,
    username: PropTypes.string,
    first_name: PropTypes.string,
    middle_name: PropTypes.string,
    last_name: PropTypes.string,
    preferred_name: PropTypes.string,
    date_of_birth: PropTypes.string,
    parentage: PropTypes.string,
    gender: PropTypes.string,
    raceEthnicity: PropTypes.string,
    isAdult: PropTypes.bool,
    studentRating: PropTypes.number,
    testingGroup: PropTypes.string,
    teacher: PropTypes.string,
    dateOfAdmission: PropTypes.string,
    ageExposed: PropTypes.number,
    languageAtHome: PropTypes.string,
    languageOfInstruction: PropTypes.string,
  })).isRequired,
  setStudents: PropTypes.func.isRequired,
  assessmentPaused: PropTypes.bool,
  assessmentStatusLoading: PropTypes.bool,
  pauseOrUnpauseAssessment: PropTypes.func.isRequired,
};

/**
 * @typedef StudentManagement
 * @property {number} assessmentId
 * @property {Student[]} students
 * @property {function} setStudents
 */

/**
 * @typedef Student
 * @property {string} student_id
 * @property {string} username
 * @property {string} first_name
 * @property {string} middle_name
 * @property {string} last_name
 * @property {string} preferred_name
 * @property {string} date_of_birth
 * @property {string} parentage
 * @property {string} race_ethnicity
 * @property {string} student_rating
 * @property {boolean} is_adult
 * @property {string} studentRating
 * @property {string} testingGroup
 * @property {string} teacher
 * @property {string} exam
 */

export default StudentManagement;
